import { useDispatch, useSelector } from 'react-redux'
import { useLayoutEffect } from 'react'
import useDataStoreItemDetails from './useDataStoreItemDetails'
import useDataStoreItemsFetchCache from './useDataStoreItemFetchCache'

/**
 * Returns requested datastore item(s) from state.data[dataStore] and fetches missing item details.
 * @param itemIds {string | string[]}: id of requested items
 * @param dataSelector {function(state: object): {}}: selector, that returns all data store item details(state.data[dataStore]).
 * @param dataFetcherActionCreator {function(itemIdsToFetch: string[]): {type: string, data: {}}}: creates an action,
 * that when dispatched, triggers the fetching of the requested data store items.
 * @param JSONStoreItemToStoreItem {function(jsonStoreItem: {}): {}}: function converting a json store-item to a
 * store-item object(class instance).
 * @param asMap {boolean}: when multiple items are requested, return items as an object mapping items by their id.
 * @param fetchAllEnabled {boolean}: enable fetching of all items, when no itemId is passed.
 * @param dataFetcherExtraParams {[] | null}: pass extra parameters to the fetcher action.
 * @returns {object | object[]}
 */
const useDataStoreItems = (
  itemIds, dataSelector, dataFetcherActionCreator, JSONStoreItemToStoreItem, asMap = false,
  fetchAllEnabled = false, dataFetcherExtraParams = null
) => {
  const { actionCanBeFired } = useDataStoreItemsFetchCache()

  // validate arguments
  if (!dataSelector) {
    throw new Error('[useDataStoreItems] dataSelector is required')
  }
  if (!dataFetcherActionCreator) {
    throw new Error('[useDataStoreItems] dataFetcherActionCreator is required')
  }
  if (!JSONStoreItemToStoreItem) {
    throw new Error('[useDataStoreItems] JSONStoreItemToStoreItem is required')
  }

  const itemDetails = useSelector(dataSelector)
  const dispatch = useDispatch()

  useLayoutEffect(
    // fetch missing item data
    () => {
      let itemIdsToFetch
      if (itemIds && typeof itemIds === 'string' && !itemDetails[itemIds]) {
        // if a single item is requested(itemIds is a string), and item data is missing, fetch item data
        itemIdsToFetch = [itemIds]
      } else if (Array.isArray(itemIds)) {
        // if multiple items are requested(itemIds is an array), and data is missing for some items, fetch missing item data
        itemIdsToFetch = itemIds.filter(itemId => !itemDetails[itemId])
      }
      if (fetchAllEnabled || itemIdsToFetch?.length) {
        const action = dataFetcherActionCreator(
          itemIdsToFetch,
          dataFetcherExtraParams || []
        )
        // fetch missing item data if there is any missing item data and data wasn't fetched yet
        if (actionCanBeFired(action)) {
          dispatch(action)
        }
      }
    },
    [
      itemIds, itemDetails, dataFetcherActionCreator, fetchAllEnabled, dataFetcherExtraParams, dispatch,
      actionCanBeFired
    ]
  )

  return useDataStoreItemDetails(itemDetails, itemIds, asMap, JSONStoreItemToStoreItem, fetchAllEnabled)
}

export default useDataStoreItems
