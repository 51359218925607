import SortParameter from './SortParameter'
import FilterExpression from './FilterExpression'
import qs from 'qs'
import qsUtilities from '../qs'

/**
 * Encapsulates list-related metadata management for pagination, sorting and filtering.
 * @param limit {int} items on page.
 * @param page {int} current page.
 * @param orderBy {SortParameter} sorting metadata.
 * @param filter {FilterExpression} filtering metadata.
 * @param qsNamespace {string|null} each qs-encoded ListMetaData parameter has this prefix. It helps with identifying
 *  the parameters of ListMetadata when the qs of multiple ListMetadata is used as search parameter on the same screen.
 */
class ListMetaData {
  constructor (limit, page, orderBy = [], filter = null, qsNamespace = null) {
    if (!Array.isArray(orderBy) || (orderBy.length && !(orderBy[0] instanceof SortParameter))) {
      throw new Error(`[ListMetaData] Invalid orderBy parameter ${orderBy}`)
    }

    if (filter !== null && !(filter instanceof FilterExpression)) {
      throw new Error(`[ListMetaData] Invalid filter parameter ${filter}`)
    }

    this.limit = limit || ListMetaData.DEFAULT_LIMIT
    this.page = page || ListMetaData.DEFAULT_PAGE
    this.orderBy = orderBy
    this.filter = filter
    this.qsNamespace = qsNamespace
  }

  toJSON () {
    return {
      limit: this.limit,
      offset: this.page,
      order_by: SortParameter.SerializeSortParameterCollectionToJSON(this.orderBy),
      filter: this.filter.toJSON()
    }
  }

  static fromJSON (json) {
    return new ListMetaData(
      json.limit,
      json.offset,
      SortParameter.SortParameterCollectionFromJSON(json.order_by),
      FilterExpression.fromJSON(json.filter)
    )
  }

  toRequestParameterString () {
    const { limit, offset, order_by, filter } = this.toRequestParameter() // eslint-disable-line
    const prefix = ListMetaData.GetRequestParameterPrefix(this.qsNamespace)

    return qs.stringify({
      [`${prefix}limit`]: limit,
      [`${prefix}offset`]: offset,
      [`${prefix}order_by`]: order_by, // eslint-disable-line
      [`${prefix}filter`]: filter
    })
  }

  toRequestParameter () {
    return ({
      limit: this.limit,
      offset: this.page,
      order_by: SortParameter.SerializeSortParameterCollectionToRequestParam(this.orderBy),
      filter: this.filter?.toJSON()
    })
  }

  static ParseFromRequestParameter (requestParameter, qsNamespace = null) {
    const parsedRequestParameter = qs.parse(
      requestParameter,
      qsUtilities.parsePrimitiveValuesOptions
    )
    const prefix = ListMetaData.GetRequestParameterPrefix(qsNamespace)

    return new ListMetaData(
      parsedRequestParameter[`${prefix}limit`],
      parsedRequestParameter[`${prefix}offset`],
      parsedRequestParameter[`${prefix}order_by`]
        ? SortParameter.SortParameterCollectionFromRequestParam(parsedRequestParameter[`${prefix}order_by`])
        : undefined,
      parsedRequestParameter[`${prefix}filter`]
        ? FilterExpression.fromJSON(parsedRequestParameter[`${prefix}filter`])
        : undefined
    )
  }

  toAxiosRequestParams () {
    return ({
      params: this.toRequestParameter()
    })
  }

  static GetRequestParameterPrefix (qsNamespace = null) {
    return qsNamespace
      ? `${qsNamespace}.`
      : ''
  }
}

ListMetaData.DEFAULT_LIMIT = 10
ListMetaData.NO_LIMIT = -1 // we signal to the api, that we want all users by passing NO_LIMIT
ListMetaData.DEFAULT_PAGE = 0

export default ListMetaData
