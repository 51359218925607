import { useCallback, useMemo, useRef } from 'react'

const cache = new Map() // fetch actions are cached in memory

/**
 * Tracks the data store item fetch actions, and facilitates the prevention of firing the same action with the same
 * parameters under a certain period of time.
 * @returns {{ actionCanBeFired: function(action: {type: string, data: object}): boolean }}
 */
const useDataStoreItemsFetchCache = () => {
  // prevent executing the same fetch twice one after the other with the same hook, by storing a reference to the previous fetch
  const previousFetchCacheKey = useRef('')

  const actionCanBeFired = useCallback(
    (action, expiresInSeconds = 10) => {
      const cacheKey = `${action.type}: ${JSON.stringify(action.data)}`
      const cachedAction = cache.get(cacheKey)
      if (typeof cachedAction !== 'object' || new Date().getTime() > cachedAction.expirationTimestamp) {
        // action wasn't executed, or the action cache has expired
        if (previousFetchCacheKey.current !== cacheKey) {
          // the current fetch is not the same as the previous one(the same fetch is not executed twice by the same hook)
          // store the action inside the cache
          cache.set(
            cacheKey,
            {
              expirationTimestamp: new Date().getTime() + expiresInSeconds * 1000
            }
          )
          // store the cache key of the executed fetch
          previousFetchCacheKey.current = cacheKey
          // action can be fired
          return true
        }
      }

      return false
    },
    []
  )

  return useMemo(
    () => ({
      actionCanBeFired
    }),
    [actionCanBeFired]
  )
}

export default useDataStoreItemsFetchCache
