import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { reduxBatch } from '@manaflair/redux-batch'
import reducers from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const bindMiddlewares = middleware => {
  const applyResult = compose(...middleware)

  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('@redux-devtools/extension')
    return composeWithDevTools(applyResult)
  }

  return applyResult
}

export function configureStore (initialState = {}) {
  const store = createStore(
    reducers(),
    initialState,
    bindMiddlewares([
      reduxBatch,
      applyMiddleware(sagaMiddleware),
      reduxBatch
    ])
  )

  sagaMiddleware.run(rootSaga)

  return store
}

const store = configureStore()

export default store
