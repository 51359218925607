import { makeActionCreator } from '../../index'

/**
 * Creates action creators for datastore management.
 * @param dataStoreTypes {{FETCH: string, FETCH_RESULT: string, MERGE: string, ADD_ITEM: string, DELETE_ITEM: string, RESET: string}}
 * @returns {{fetch: function(ids: string[], extraParams: any, onFinish: function): object, fetchResult: function(result: AxiosResponse<object[]>, callback: function(storeValue: object)): object, merge: function(data: {}): object, addItem: function(id: string, item: {}): object, deleteItem: function(id: string): object, reset: function(): object}}
 */
const createDataStoreActions = dataStoreTypes => ({
  fetch: makeActionCreator(dataStoreTypes.FETCH, 'ids', 'extraParams', 'onFinish'),
  fetchResult: makeActionCreator(dataStoreTypes.FETCH_RESULT, 'result', 'callback'),
  merge: makeActionCreator(dataStoreTypes.MERGE, 'data'),
  addItem: makeActionCreator(dataStoreTypes.ADD_ITEM, 'id', 'item'),
  deleteItem: makeActionCreator(dataStoreTypes.DELETE_ITEM, 'id'),
  reset: makeActionCreator(dataStoreTypes.RESET)
})

export default createDataStoreActions
