/**
 * Describes a filtering operation on an attribute.
 * @param filterType {FilterParameter.FILTER_TYPES} filter operation type.
 * @param attributeName {string} the attribute the operation is executed on.
 * @param filterValue {string|number|boolean|Date} the value the attribute is matched against.
 */
class FilterParameter {
  constructor (filterType, attributeName, filterValue) {
    const allowedFilterTypes = Object.values(FilterParameter.FILTER_TYPES)

    if (!allowedFilterTypes.includes(filterType)) {
      throw new Error(`[FilterParameter] Invalid filter parameter type: ${filterType}`)
    }

    this.type = filterType
    this.attributeName = attributeName
    this.value = filterValue
  }

  toJSON () {
    return {
      type: FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[this.type],
      attributeName: this.attributeName,
      value: this.value
    }
  }

  toString () {
    return `${FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[this.type]}(${this.attributeName}, ${this.value})`
  }

  static fromJSON ({ attributeName, type, value }) {
    return new FilterParameter(
      FilterParameter.FILTER_TYPE_DESERIALIZATION_MAPPING[type],
      attributeName,
      value
    )
  }

  static EQUALS (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.EQUALS, attributeName, value)
  }

  static EQUALS_CASE_INSENSITIVE (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.EQUALS_CASE_INSENSITIVE, attributeName, value)
  }

  static GREATER_THAN (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.GREATER_THAN, attributeName, value)
  }

  static GREATER_THAN_OR_EQUAL (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.GREATER_THAN_OR_EQUAL, attributeName, value)
  }

  static LOWER_THAN (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.LOWER_THAN, attributeName, value)
  }

  static LOWER_THAN_OR_EQUAL (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.LOWER_THAN_OR_EQUAL, attributeName, value)
  }

  static CONTAINS (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.CONTAINS, attributeName, value)
  }

  static CONTAINS_CASE_INSENSITIVE (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.CONTAINS_CASE_INSENSITIVE, attributeName, value)
  }

  static IN (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.IN, attributeName, value)
  }

  static ON_SAME_DAY_AS (attributeName, value) {
    return new FilterParameter(FilterParameter.FILTER_TYPES.ON_SAME_DAY_AS, attributeName, value)
  }

  static isJSONValid (json) {
    const jsonKeys = Object.keys(json)

    return jsonKeys.length === 3 && jsonKeys.includes('attributeName') && jsonKeys.includes('type') && jsonKeys.includes('value')
  }
}

FilterParameter.FILTER_TYPES = {
  EQUALS: '=',
  EQUALS_CASE_INSENSITIVE: '=i',
  GREATER_THAN: 'GT',
  GREATER_THAN_OR_EQUAL: 'GTE',
  LOWER_THAN: 'LT',
  LOWER_THAN_OR_EQUAL: 'LTE',
  CONTAINS: 'CONTAINS',
  CONTAINS_CASE_INSENSITIVE: 'CONTAINS_CASE_INSENSITIVE',
  IN: 'IN',
  ON_SAME_DAY_AS: 'ON_SAME_DAY_AS'
}

FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING = {
  [FilterParameter.FILTER_TYPES.EQUALS]: '=',
  [FilterParameter.FILTER_TYPES.EQUALS_CASE_INSENSITIVE]: '=i',
  [FilterParameter.FILTER_TYPES.GREATER_THAN]: 'GT',
  [FilterParameter.FILTER_TYPES.GREATER_THAN_OR_EQUAL]: 'GTE',
  [FilterParameter.FILTER_TYPES.LOWER_THAN]: 'LT',
  [FilterParameter.FILTER_TYPES.LOWER_THAN_OR_EQUAL]: 'LTE',
  [FilterParameter.FILTER_TYPES.CONTAINS]: 'CONTAINS',
  [FilterParameter.FILTER_TYPES.CONTAINS_CASE_INSENSITIVE]: 'CONTAINS_CASE_INSENSITIVE',
  [FilterParameter.FILTER_TYPES.IN]: 'IN',
  [FilterParameter.FILTER_TYPES.ON_SAME_DAY_AS]: 'ON_SAME_DAY_AS'
}

FilterParameter.FILTER_TYPE_DESERIALIZATION_MAPPING = {
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.EQUALS]]: FilterParameter.FILTER_TYPES.EQUALS,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.EQUALS_CASE_INSENSITIVE]]: FilterParameter.FILTER_TYPES.EQUALS_CASE_INSENSITIVE,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.GREATER_THAN]]: FilterParameter.FILTER_TYPES.GREATER_THAN,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.GREATER_THAN_OR_EQUAL]]: FilterParameter.FILTER_TYPES.GREATER_THAN_OR_EQUAL,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.LOWER_THAN]]: FilterParameter.FILTER_TYPES.LOWER_THAN,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.LOWER_THAN_OR_EQUAL]]: FilterParameter.FILTER_TYPES.LOWER_THAN_OR_EQUAL,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.CONTAINS]]: FilterParameter.FILTER_TYPES.CONTAINS,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.CONTAINS_CASE_INSENSITIVE]]: FilterParameter.FILTER_TYPES.CONTAINS_CASE_INSENSITIVE,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.IN]]: FilterParameter.FILTER_TYPES.IN,
  [FilterParameter.FILTER_TYPE_SERIALIZATION_MAPPING[FilterParameter.FILTER_TYPES.ON_SAME_DAY_AS]]: FilterParameter.FILTER_TYPES.ON_SAME_DAY_AS
}

FilterParameter.FILTER_VALUES = {
  EMPTY: '__FilterParameter.FILTER_VALUES.EMPTY__'
}

export default FilterParameter
